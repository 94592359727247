const herospace = document.querySelector('.herospace__gallery')
const nav = document.querySelector('.site-header__navigation')
const navBtn = document.querySelector('.site-header__navigation__trigger')

document.addEventListener('DOMContentLoaded', function () {
    document.querySelector('html').classList.remove('no-js')

    if (herospace) {
        const heroFlickity = new Flickity(herospace, {
            pageDots: true,
            prevNextButtons: false,
            autoPlay: 6000
        })
    }

    if (nav && navBtn) {
        navBtn.addEventListener('click', function () {
            toggleClass(nav, 'is-open')
        })
    }
});
